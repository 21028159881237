import "../Styles/login.scss";
import Input from "../components/Util/Input";
import logo from "../assets/images/logo.png";
import { useEffect, useRef, useState } from "react";
import Loading from "../components/Util/Loading";

export default function Login(props) {
  const rfEmail = useRef();
  const rsEmail = useRef();
  const rfPassword = useRef();
  const code = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
  });
  const ref = useRef();
  const [token, setToken] = useState(null);
  const [active, setActive] = useState("login");
  const [leftA, setLeftA] = useState("100%");
  const [rightA, setRightA] = useState("-100%");
  const [leftB, setLeftB] = useState("-100%");
  const [rightB, setRightB] = useState("100%");
  const [leftC, setLeftC] = useState("-100%");
  const [rightC, setRightC] = useState("100%");
  var jwt = require("jsonwebtoken");
  useEffect(() => {
    if (active == "login") {
      setLeftA(0);
      setRightA("50%");
      setLeftB("-100%");
      setRightB("100%");
      setLeftC("-100%");
      setRightC("100%");
    } else if (active == "code") {
      setLeftC("50%");
      setRightC(0);
      setLeftB("50%");
      setRightB(0);
      setLeftA("100%");
      setRightA("-100%");
    } else {
      setLeftB("50%");
      setRightB(0);
      setLeftA("100%");
      setRightA("-100%");
    }
  }, [active]);

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            setIsErr(data.success);
            setToken(data.token);
            setLoading(false);
            setTimeout(() => {
              setActive("code");
              setIsErr("");
            }, 500);
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const codeConfirmation = () => {
    let d = { Code: code.current.value.toLowerCase().trim() };
    setIsErr("");

    if (d.Code.length != 6) return setIsErr("Invalid Code!");
    try {
      if (token) {
        try {
          var decoded = jwt.decode(token);
          if (Date.now() >= decoded.exp * 1000) {
            setActive("Login");
          } else {
            console.log(decoded);
            if (d.Code === decoded.Code) {
              localStorage.setItem(
                "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW",
                token
              );
              window.location.href = "/";
            } else {
              setIsErr("Incorrect authentication code!");
            }
          }
        } catch (error) {
          setIsErr("Sign up again!");
        }
      }
    } catch (error) {
      setIsErr("Validation failed! Login again");
    }
  };

  const reset = () => {
    let d = body;
    d.Email = rsEmail.current.value.toLowerCase().trim();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");

    if (validateEmail(body.Email)) {
      setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkngdfhgfhtkn", data.token);
            localStorage.removeItem("path");
            setIsErr(data.success);
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="login" ref={ref}>
      <div className="container">
        <div style={{ opacity: active == "login" ? 1 : 0 }}>
          <div id="sign-in" className={"sign-in "} style={{ left: leftA }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <img src={logo} alt="" />
              <h1>Sign In</h1>
              <h6>{isErr}</h6>
              <Input ref={rfEmail} type="email" label="Email" />
              <Input ref={rfPassword} type="password" label="Password" />
              <button
                onClick={() => {
                  login();
                }}
              >
                Sign In
              </button>
            </form>
          </div>
          <div className="right-panel" style={{ left: rightA }}>
            <div>
              <h1>Finnai Billing App</h1>
              <p>
                This billing app was developed by Finnai Tech Solutions and
                remains property of Finnai Tech.
              </p>
              <button
                onClick={() => {
                  setActive("reset");
                }}
                className="hidden"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
        <div style={{ opacity: active == "reset" ? 1 : 0 }}>
          <div className={"reset"} style={{ left: leftB }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <img src={logo} alt="" />
              <h1>Reset Password</h1> <h6>{isErr}</h6>
              <Input ref={rsEmail} type="email" label="Email" />
              <button
                onClick={() => {
                  reset();
                }}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="left-panel" style={{ left: rightB }}>
            <div>
              <h1>Finnai Billing App</h1>
              <p>
                This billing app was developed by Finnai Tech Solutions and
                remains property of Finnai Tech.
              </p>
              <button
                onClick={() => {
                  setActive("login");
                }}
                className="hidden"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
        <div style={{ opacity: active == "code" ? 1 : 0 }}>
          <div className={"reset"} style={{ left: leftC }}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <img src={logo} alt="" />
              <h1>Authentication Code</h1> <h6>{isErr}</h6>
              <Input ref={code} type="number" label="Authentication Code" />
              <button
                onClick={() => {
                  codeConfirmation();
                }}
              >
                Submit
              </button>
            </form>
          </div>
          <div className="left-panel" style={{ left: rightC }}>
            <div>
              <h1>2-Factor Authentication</h1>
              <p>
                We have sent an authentication code to your registered email.
                Please check your email to proceed.
              </p>
              <button
                onClick={() => {
                  setActive("login");
                }}
                className="hidden"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}
