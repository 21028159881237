import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import contactDev from "../../assets/images/dev.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTasks,
  faUsers,
  faGear,
  faSignOut,
  faMoneyBill,
  faUserFriends,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

export default function Navigation(props) {
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    const token = localStorage.getItem(
      "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
    );

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
      } catch (error) {}
    }
  }, []);

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    console.log(params.active);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
      >
        <div
          onClick={() => {
            if (params.options?.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            // setShowing(true);
          }}
          className={params.link == params.active ? "active" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "20px auto" : "auto",
          }}
        >
          <FontAwesomeIcon icon={params.icon} />
          {params.showing && <p>{params.txt}</p>}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "225px" : "fit-content" }}
      className="navigation"
    >
      <div>
        <div className="logo">
          <img
            style={{ maxWidth: props.showing ? "200px" : "30px" }}
            src={logo}
            alt=""
          />
        </div>
        <div className="line-container">
          <div className="circle-point start-point"></div>
          <div className="circle-point end-point"></div>
        </div>
        <Item
          txt="Home"
          url="/"
          active={pathname[1]}
          link=""
          icon={faHome}
          options={[]}
          showing={props.showing}
        />
        <Item
          txt="Invoices"
          url="/invoices"
          link="invoices"
          active={pathname[1]}
          icon={faFileInvoiceDollar}
          options={[]}
          showing={props.showing}
        />
        {currentUser?.Role === "Admin" && (
          <Item
            txt="Payroll"
            url="/payroll"
            link="payroll"
            icon={faMoneyBill}
            active={pathname[1]}
            code="&#xf0d6;"
            options={[]}
            showing={props.showing}
          />
        )}
        <Item
          url="/files"
          txt="File Manager"
          link="files"
          active={pathname[1]}
          icon={faTasks}
          options={[]}
          showing={props.showing}
        />
        <Item
          txt="Customers"
          url="/customers"
          link="customers"
          active={pathname[1]}
          icon={faUserFriends}
          options={[]}
          showing={props.showing}
        />
        <Item
          txt="Users"
          url="/users"
          link="users"
          active={pathname[1]}
          icon={faUsers}
          options={[]}
          showing={props.showing}
        />
        <Item
          txt="Settings"
          url="/settings"
          link="settings"
          active={pathname[1]}
          icon={faGear}
          code="&#xf013;"
          options={[]}
          showing={props.showing}
        />
        <Item
          txt="Signout"
          active={pathname[1]}
          icon={faSignOut}
          url="/logout"
          options={[]}
          showing={props.showing}
        />{" "}
      </div>

      <div className="contactdev">
        <img
          style={{ maxWidth: props.showing ? "200px" : "30px" }}
          src={contactDev}
          alt=""
        />
      </div>
    </div>
  );
}
