import "../../Styles/stats.scss";
import { FaFileInvoiceDollar } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { FiRefreshCcw } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export default function Stats(props) {
  const [numbers, setNumbers] = useState(null);
  const [sdata, setSData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  var jwt = require("jsonwebtoken");

  useEffect(() => {
    fetch(`/api/invoices/getadminstats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setSData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    fetch(`/api/invoices/numbers`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setNumbers(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    const token = localStorage.getItem(
      "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
    );
    if (token) {
      try {
        var decoded = jwt.decode(token);
        setUser(decoded.Name);
        if (decoded.Role === "Admin" || decoded.Role === "Super Admin") {
        } else window.location.href = "/";
      } catch (error) {}
    }
  }, []);



  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="stats">
      <div className="welcome">
        <div>
          <h1>Dashboard</h1>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Invoices</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <FaFileInvoiceDollar className="ts" />
                  <div>
                    <h4>{sdata ? withCommas(sdata?.AllTasks) : 0}</h4>
                    <p>Total Invoices</p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="gauge">
                  <h3>Revenue Collection Rate</h3>
                  <GaugeChart
                    className="gg"
                    id="gauge-chart2"
                    nrOfLevels={3}
                    colors={["red", "orange", "green"]}
                    arcWidth={0.4}
                    percent={
                      sdata ? (sdata?.Collected / sdata?.Billed).toFixed(2) : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.5}
                data={sdata ? sdata.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Revenue Collection</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <div className="hl">
                  <h4>
                    KSh{" "}
                    {sdata
                      ? (sdata?.Collected - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </h4>
                  <h5>Total Collected</h5>
                </div>

                <div className="div1auto">
                  <div style={{ backgroundColor: "orange" }}></div>
                  <p>Total Billed: KSh </p>
                  <h6>
                    {sdata
                      ? (sdata?.Billed - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "green" }}></div>
                  <p>Collected: KSh</p>
                  <h6>
                    {sdata
                      ? (sdata?.Collected - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "red" }}></div>
                  <p>Pending: KSh </p>
                  <h6>
                    {sdata
                      ? (sdata?.Billed - sdata?.Collected).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                      : "0.00"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="links">
        <div
          onClick={() => {
            window.location.href = "/invoices";
          }}
          className="section"
        >
          <div className="br"></div>
          <h2>Invoices</h2>
          <p>{numbers ? numbers?.Invoices : 0}</p>
          <FontAwesomeIcon
            size="sm"
            className="ic"
            icon={faArrowRightLong}
          />{" "}
          <FontAwesomeIcon className="ic" icon={faArrowRightLong} />
        </div>
        <div
          onClick={() => {
            window.location.href = "/customers";
          }}
          className="section"
        >  <div className="br"></div>
          <h2>Customers</h2>
          <p>{numbers ? numbers?.Customers : 0}</p>
          <FontAwesomeIcon className="ic" icon={faArrowRightLong} />
        </div>
        <div
          onClick={() => {
            window.location.href = "/files";
          }}
          className="section"
        >  <div className="br"></div>
          <h2>Files</h2>
          <p>{numbers ? numbers?.Files : 0}</p>
          <FontAwesomeIcon className="ic" icon={faArrowRightLong} />
        </div>
        <div
          onClick={() => {
            window.location.href = "/users";
          }}
          className="section"
        >  <div className="br"></div>
          <h2>Users</h2>
          <p>{numbers ? numbers?.Users : 0}</p>
          <FontAwesomeIcon className="ic" icon={faArrowRightLong} />
        </div>
      </div>
    </div>
  );
}
