import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import Settings from "../components/Settings/Settings";
import WPStats from "../components/Stats/WPStats";
import PortalUsers from "../components/Users/PortalUsers";
import MUsers from "../components/Users/MUsers";
import Main from "../components/Folders/Main";
import Invoicing from "../components/Invoicing/Invoicing";
import NewInvoice from "../components/Invoicing/NewInvoice";
import PreviewInvoice from "../components/Invoicing/PreviewInvoice";
import Payroll from "../components/Payroll/Payroll";
import Payslip from "../components/Payroll/Payslip";
import Employees from "../components/Payroll/Employees";
import EditInvoice from "../components/Invoicing/EditInvoice";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [shownavigation, setShowNavigation] = useState(false);
  const [path, setPath] = useState(null);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "225px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation
            showing={showing}
            setShowing={setShowing}
            shownavigation={shownavigation}
          />
        </div>
        <div className="right_panel">
          <Header
            showing={showing}
            setShowing={setShowing}
            setShowNavigation={setShowNavigation}
            shownavigation={setShowNavigation}
          />

          <div className="full">
            {pathname[1] === "" && <WPStats showing={showing} />}

            {pathname[1] === "customers" && <MUsers />}
            {pathname[1] === "users" && <PortalUsers />}

            {pathname[1] === "files" && <Main path={path} setPath={setPath} />}

            {pathname[1] === "invoices" && pathname.length == 2 && (
              <Invoicing path={path} setPath={setPath} />
            )}

            {pathname[1] === "invoices" && pathname[2] === "new" && (
              <NewInvoice path={path} setPath={setPath} />
            )}

            {pathname[1] === "invoices" &&
              pathname[2] === "edit" &&
              pathname.length > 2 && (
                <EditInvoice path={path} setPath={setPath} />
              )}

            {pathname[1] === "invoices" && pathname[2] === "preview" && (
              <PreviewInvoice path={path} setPath={setPath} />
            )}

            {pathname[1] === "payroll" && pathname.length == 2 && (
              <Payroll showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "payroll" && pathname[2] === "payslip" && (
              <Payslip showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "payroll" && pathname[2] === "employees" && (
              <Employees showing={showing} setShowing={setShowing} />
            )}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
