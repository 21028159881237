import React, { useState, useEffect, useRef } from "react";
import UserBox from "./UserBox";
import SelectedUser from "./SelectedUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import Select from "../Util/Select";
import Input from "../Util/Input";
import Button from "../Util/Button";
import "../../Styles/users.scss";
import Pagination from "../Util/Pagination";
import CustomerBox from "./CustomerBox";
import SelectedCustomer from "./SelectedCustomer";

export default function MUsers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/customers/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        console.log(data);
        setLoading(false);
        setData(data);
        if (data?.data?.length > 0) {
          setUserID(data.data[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    selectedUser();
  }, [userID]);

  const selectedUser = () => {
    setLoading(true);
    fetch(`/api/customers/${userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setUserDetails(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/customers/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.data.length > 0) {
          setUserID(data.data[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="users">
      <div className="list">
        <div className="utp">
          <h3>My Customers</h3>
          <p
            onClick={() => {
              setClicked(true);
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New User
          </p>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v != "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
        </div>
        <hr />
        <div className="div31">
          <div>
            <div className="lcontainer">
              <div className="user-list">
                {data &&
                  data?.data?.length > 0 &&
                  data?.data?.map((item, index) => {
                    return (
                      <CustomerBox
                        key={index}
                        item={item}
                        userID={userID}
                        setUserID={setUserID}
                      />
                    );
                  })}
              </div>
            </div>
            {data && (
              <Pagination
                totalItems={data?.total}
                currentPage={offset}
                onPageChange={(v) => {
                  setOffset(v);
                }}
              />
            )}
          </div>
          <div className="selected">
            <h4>User Details</h4>
            <hr />

            {userDetails ? (
              userDetails && (
                <SelectedCustomer
                  setLoading={setLoading}
                  userDetails={userDetails}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  url="customers"
                />
              )
            ) : (
              <>
                <p>Click on a user to see their details</p>
              </>
            )}
          </div>
        </div>
        {loading && <Loading />}
      </div>
      {clicked && (
        <Popup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
}

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fname = useRef();
  const sname = useRef();
  const email = useRef();
  const phone = useRef();
  const county = useRef();
  const ContactPersonPosition = useRef();
  const cpassword = useRef();
  const Company = useRef();
  const Address = useRef();
  const Logo = useRef();
  const PreferredPayment = useRef();
  const Currency = useRef();
  const Type = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Company: Company.current.value,
      Address: Address.current.value,
      PreferredPayment: PreferredPayment.current.value,
      Currency: Currency.current.value,
      ContactPersonName: fname.current.value.trim(),
      ContactPersonPhone: phone.current.value,
      ContactPersonEmail: email.current.value.toLowerCase().trim(),
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!body.ContactPersonPhone || body.ContactPersonPhone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }

      const chck = Object.values(body);
      chck.map((item) => {
        if (item == "") {
          result = false;
        }
      });

      if (!result) {
        setError("All fields are required!");
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/customers/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="popup">
      <div className="wrap">
        <div className="head">
          <h3>New User</h3>
          <FontAwesomeIcon
            onClick={() => {
              props.setClicked(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>

        <div className="new">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="div2equal">
              <Input ref={Company} type="text" label="Company Name *" />
              <Input ref={Address} type="text" label="Company Address *" />
            </div>

            <div className="div2equal">
              <Select
                ref={PreferredPayment}
                data={["Bank Transfer", "Cheque", "Mobile Money", "Cash"]}
                type="text"
                label="Preferred payment mode *"
              />
              <Select
                ref={Currency}
                data={["KSh", "US$", "Euro"]}
                type="text"
                label="Currency *"
              />
            </div>
            <h4>Contact Person</h4>
            <hr />
            <div className="div3equal">
              <Input ref={fname} type="text" label="Name *" />
              <Input ref={email} type="email" label="Email *" />
              <Input ref={phone} type="number" label="Phone *" />
            </div>

            <h6>{error}</h6>
            <Button handleClick={createUser} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};
