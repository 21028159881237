import { useRef, useState, useEffect } from "react";
import Input from "../Util/Input";
import WaveLoading from "../Util/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowRight,
  faList,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Util/Button";
import Loading from "../Util/Loading";

export default function NewInvoice(props) {
  const [loading, setLoading] = useState(false);
  const [createItem, setCreateItem] = useState(false);
  const [listItem, setListItem] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [su, setSU] = useState(null);
  const [total, setTotal] = useState(0.0);
  const [subtotal, setSubTotal] = useState(0.0);
  const [wtax, setWTax] = useState(0.0);
  const [wtaxStatus, setWTaxStatus] = useState(false);
  const [vatStatus, setVATStatus] = useState(false);
  const [vat, setVAT] = useState(0.0);
  const [currency, setCurrency] = useState("KSh");
  const [refresh, setRefresh] = useState(false);
  const [userID, setUserID] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [bdata, setBdata] = useState([]);
  const [billing, setBilling] = useState([]);
  const sdt = useRef();
  const title = useRef();
  const date = useRef();
  var jwt = require("jsonwebtoken");

  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem(
      "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
    );

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setUserID(decoded.UserID);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    let sbt = 0.0;
    bdata.map((e) => {
      sbt += parseInt(e.Rate) * parseInt(e.Qty);
    });
    setSubTotal(sbt);
    let wtx = 0;
    let vt = 0;

    if (vatStatus) {
      setTotal(sbt);
      vt = sbt * 0.1;
      setVAT(vt);
      setTotal(sbt + vt);
    } else {
      setVAT(0);
      setTotal(sbt);
    }
  }, [bdata, vat, wtax, subtotal, total, wtaxStatus, vatStatus]);

  function searchByPhone(q) {
    fetch(`/api/customers/seachone/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setFO(data);
      })
      .catch((e) => {});
  }

  const createDocument = (e) => {
    setError("");
    if (bdata.length == 0) return setError("You must add an invoice item!");
    const body = {
      UserID: userID,
      CustomerID: customerID,
      Date: date.current.value,
      Title: title.current.value,
      ItemsList: bdata,
      SubTotal: subtotal,
      WithholdingTax: wtax,
      VAT: vat,
      Total: total,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/invoices/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/invoices/preview/" + data.id;
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const SOList = (params) => {
    console.log(params.item);
    return (
      <div
        onClick={() => {
          setCustomerID(params.item.UserID);
          setFOData(params.item);
          setFO(null);
        }}
        className="so_list"
      >
        <h2>{params.item.Company}</h2>
        <h5>{params.item.Address}</h5>
        <h5>
          <b>
            Contact Person: {params.item.ContactPersonName}
            {", "}
            {params.item.ContactPersonEmail}
          </b>
        </h5>
      </div>
    );
  };

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="invoicing">
      <div className="container">
        <div className="createworkplan">
          <div className="cpcontainer">
            <div className="tp">
              <h3>Create Invoice</h3>
              <h4
                onClick={() => {
                  props.setCreating(false);
                }}
              >
                <FontAwesomeIcon className="fa" icon={faArrowRight} /> Invoices
              </h4>
            </div>
            <div className="bdy">
              <div className="div2equal">
                <div className="section">
                  <h4>Search Customer</h4>
                  <hr />
                  <br />
                  <div className="search">
                    <Input
                      type="text"
                      handleChange={(e) => {
                        searchByPhone(e);
                      }}
                      label="Name"
                      hint="Type to search"
                    />
                    {fo && (
                      <div className="search_list">
                        {fo.map((item, i) => {
                          return <SOList key={i} item={item} />;
                        })}
                      </div>
                    )}
                  </div>

                  <div>
                    {fodata && (
                      <SOItem
                        clearEvent={() => {
                          setFOData(null);
                          setCustomerID("");
                        }}
                        item={fodata}
                      />
                    )}
                  </div>
                </div>
                <div className="section">
                  <h4>Invoice Details</h4>
                  <hr />
                  <br />
                  <Input ref={title} type="text" label="LPO/LSO/Title *" />
                  <Input
                    ref={date}
                    type="date"
                    label="Due Date *"
                    value={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>
              <div className="section">
                <h4>Billing Items</h4>
                <hr />
                <div className="tphead">
                  <h3>Item</h3>
                  <h3>Qty</h3>
                  <h3>Rate (KSh)</h3>
                  <h3>Total (KSh)</h3>
                  <h3></h3>
                </div>
                {bdata &&
                  bdata.map((item, i) => {
                    return (
                      <BillingItem
                        bdata={bdata}
                        setBdata={setBdata}
                        key={i}
                        index={i}
                        item={item}
                      />
                    );
                  })}

                <div className="actions">
                  <FontAwesomeIcon
                    onClick={() => {
                      setCreateItem(true);
                    }}
                    className="fa"
                    icon={faAdd}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      setListItem(true);
                    }}
                    className="fa"
                    icon={faList}
                  />
                </div>
              </div>
              <h3>
                <b>Sub Total ({currency}):</b> {withCommas(subtotal.toFixed(2))}
              </h3>
              <h3>
                <input
                  onChange={(e) => {
                    setVATStatus(e.target.checked);
                  }}
                  type="checkbox"
                  name=""
                  id=""
                  checked={vatStatus}
                />{" "}
                <b>Service Fee (10%) ({currency}):</b>{" "}
                {withCommas(vat.toFixed(2))}
              </h3>
              <h3>
                <b>Total ({currency}):</b> {withCommas(total.toFixed(2))}
              </h3>
              <h6>{error}</h6>
              <button
                onClick={() => {
                  createDocument();
                }}
              >
                Submit
              </button>
              {createItem && (
                <CreateBillingItem
                  bdata={bdata}
                  setBdata={setBdata}
                  setCreateItem={setCreateItem}
                />
              )}
              {listItem && (
                <BillingList
                  bdata={bdata}
                  setBdata={setBdata}
                  data={billing}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setListItem={setListItem}
                />
              )}
            </div>
          </div>
        </div>

        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const BillingItem = (props) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="tprow">
      <h2>{props?.item?.Item}</h2>
      <h2>{props?.item?.Qty}</h2>
      <h2>{withCommas(props?.item?.Rate)}</h2>
      <h2>{withCommas(props?.item?.Rate * props?.item?.Qty)}</h2>
      <FontAwesomeIcon
        onClick={() => {
          const newArray = [
            ...props.bdata.slice(0, props.index),
            ...props.bdata.slice(props.index + 1),
          ];
          props.setBdata(newArray);
        }}
        className="fa-times"
        icon={faTimes}
      />
    </div>
  );
};

const CreateBillingItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const item = useRef();
  const qty = useRef();
  const rate = useRef();

  const createDocument = (e) => {
    let body = {
      Item: item.current.value,
      Qty: parseInt(qty.current.value),
      Rate: parseInt(rate.current.value),
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/billing/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setTimeout(() => {
          props.setCreateItem(false);
        }, 1000);
        props.setBdata([...props.bdata, body]);
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div className="bi_create">
      <div className="wrap">
        <FontAwesomeIcon
          onClick={() => {
            props.setCreateItem(false);
          }}
          className="fa-times"
          icon={faTimes}
        />
        <h4>Billing Item</h4>
        <hr />
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="div3a">
            <Input ref={item} type="text" label="Item" />
            <Input ref={qty} type="number" label="Quantity" />
            <Input ref={rate} type="number" label="Rate" />
          </div>
          {/* <h6>{error}</h6> */}
          <Button handleClick={createDocument} value="Submit" />
        </form>
      </div>
      {loading && <Loading />}
    </div>
  );
};

const SOItem = (props) => {
  return (
    <>
      {props.item && (
        <div className="item">
          <div>
            <h4></h4>
            <FontAwesomeIcon
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
              icon={faTimes}
            />
          </div>

          <hr />
          <h2>{props.item.Company}</h2>
          <h5>{props.item.Address}</h5>
          <h5>
            <b>
              Contact Person: {props.item.ContactPersonName}
              {", "}
              {props.item.ContactPersonEmail}
            </b>
          </h5>
        </div>
      )}
    </>
  );
};

const BillingList = (props) => {
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    fetch(`/api/billing`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  function deleteUser(id) {
    fetch(`/api/billing/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((err) => {});
  }
  const BList = (params) => {
    return (
      <div className="check">
        <p
          onClick={() => {
            props.setBdata([...props.bdata, params.item]);
          }}
        >
          {params.item.Item}
        </p>
        <p
          onClick={() => {
            props.setBdata([...props.bdata, params.item]);
          }}
        >
          {params.item.Qty} pcs
        </p>
        <p
          onClick={() => {
            props.setBdata([...props.bdata, params.item]);
          }}
        >
          @ {withCommas(params.item.Rate)}
        </p>
        <FontAwesomeIcon
          onClick={() => {
            deleteUser(params.item.ID);
          }}
          icon={faTimes}
          className="fa-close"
        />
      </div>
    );
  };

  return (
    <div className="bi_create">
      <div className="wrap">
        <FontAwesomeIcon
          onClick={() => {
            props.setListItem(false);
          }}
          icon={faTimes}
          className="fa-times"
        />
        <div className="checks">
          <h4>Add Checks *</h4>
          <hr />
          <div className="div2equal">
            {data &&
              data?.length > 0 &&
              data?.map((item, i) => {
                return <BList key={i} index={i} item={item} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
