import "../../Styles/invoicing.scss";
import { FaFileInvoiceDollar } from "react-icons/fa";
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPerson,
  faPhone,
  faSearch,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";

export default function Invoicing(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [sdata, setSData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  var jwt = require("jsonwebtoken");

  useEffect(() => {
    fetch(`/api/invoices/getadminstats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setSData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    const token = localStorage.getItem(
      "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
    );
    if (token) {
      try {
        var decoded = jwt.decode(token);
        setUser(decoded.Name);
        if (decoded.Role === "Admin" || decoded.Role === "Super Admin") {
        } else window.location.href = "/";
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/invoices/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh]);

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/customers/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="invoicing">
      <div className="list">
        <div className="utp">
          <h3>My Invoices</h3>
          <p
            onClick={() => {
              window.location.href = "/invoices/new";
            }}
          >
            <FontAwesomeIcon className="fa-add" icon={faUserPlus} /> New Invoice
          </p>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Customer ..."
              onChange={(e) => {
                const v = e.target.value;
                if (v != "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <div className="sicon">
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
        </div>
        <hr />

        <div>
          <div className="lcontainer">
            <div className="user-list">
              {data &&
                data?.data?.length > 0 &&
                data?.data?.map((item, index) => {
                  return (
                    <InvoiceItem
                      key={index}
                      item={item}
                      userID={userID}
                      setUserID={setUserID}
                    />
                  );
                })}
            </div>
          </div>
          {data && (
            <Pagination
              totalItems={data?.total}
              currentPage={offset}
              onPageChange={(v) => {
                setOffset(v);
              }}
            />
          )}
        </div>

        {loading && <Loading />}
      </div>
    </div>
  );
}

const TopItem = (props) => {
  const [amt, setAmt] = useState(0);

  useEffect(() => {
    setAmt(props.amt);
  }, [props.amt]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div style={{ backgroundImage: `url(${props.img})` }} className="tp_item">
      <h4>{props.title}</h4>
      <h3>{withCommas(amt)}</h3>
    </div>
  );
};

const InvoiceItem = (props) => {
  const [color, setColor] = useState("orange");
  const [paid, setPaid] = useState(0);

  useEffect(() => {
    if (props.item) {
      fetch(`/api/payments/getallbyinvoiceid/${props.item?.ID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          let d = 0;

          data.map((i) => {
            d += parseInt(i.Amount);
          });
          setPaid(d);
        })
        .catch((e) => {});
    }
  }, [props.item]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    if (props?.item?.Total === props?.item?.Balance) {
      setColor("orange");
    } else {
      setColor("green");
    }
  }, [props.item]);

  return (
    <div
      className={
        props.userID === props?.item?.UserID
          ? "customer-box active"
          : "customer-box"
      }
      onClick={() => {
        window.location.href = "/invoices/preview/" + props?.item?.ID;
      }}
    >
      <div className="left">
        <h2
          style={{
            backgroundColor: color,
          }}
        >
          <span>KSh {withCommas(props?.item?.Total)}</span>
        </h2>
      </div>
      <div className="right">
        <h3 className="title">{props?.item?.Company}</h3>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faUser}
          />
          {props?.item?.ContactPersonName} - {props?.item?.ContactPersonPhone} -{" "}
          {props?.item?.ContactPersonEmail}
        </p>

        <p className="calendar">{props?.item?.Date}</p>
        <div className="status">
          {props.item?.Invoice ? "Sent" : "Not Sent"}
        </div>
      </div>

      <div className="bal">
        <p>
          Balance: KSh{" "}
          {paid
            ? (props.item.Total - paid).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : (props.item.Total - 0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
        </p>
      </div>
    </div>
  );
};
