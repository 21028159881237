import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHome,
  faSignOut,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function Header(props) {
  const [nav, setNav] = useState(false);
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const [timeUntilLogout, setTimeUntilLogout] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(
      "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
    );

    if (token) {
      try {
        const decoded = jwt.decode(token);
        setCurrentUser(decoded);

        // Get the expiration time from the token
        const expirationTime = decoded.exp * 1000; // Convert to milliseconds
        const updateTimeRemaining = () => {
          const currentTime = Date.now();
          const remainingTime = expirationTime - currentTime;
          setTimeUntilLogout(remainingTime);
        };

        // Initial call to set the time
        updateTimeRemaining();

        // Update every minute (60000 ms)
        const intervalId = setInterval(updateTimeRemaining, 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, []);

  // Convert milliseconds to minutes and seconds
  const formatTime = (time) => {
    if (time <= 0) {
      // localStorage.removeItem(
      //   "P4K4eoLR9R7swgPMBIIIqjJtdCl5CSlV8hD4ip0Tm1VQ4eRYEODihpkxKQBuKD8rk1Tl8jJOBOzCxfPP41gJvgQRvSF8llzVnmKW"
      // );
      // window.location.href = "/login";
      return;
    }
    const minutes = Math.floor((time / 1000 / 60) % 60);
    return `${minutes} minutes`;
  };

  return (
    <>
      <div className="header">
        <h4>{currentUser?.Name}</h4>
        <p>Logout in: {formatTime(timeUntilLogout)}</p>
      </div>
      {nav && <MobileHeader setNav={setNav} />}
    </>
  );
}

const MobileHeader = (props) => {
  const pathname = window.location.pathname.split("/");

  return (
    <div className="mobheader">
      <FontAwesomeIcon
        onClick={() => {
          props.setNav(false);
        }}
        icon={faTimes}
        className="fa-times"
      />
      <p></p>

      <h1>Billing App</h1>

      <hr />
      <Item
        txt="Home"
        url="/"
        active={pathname[1]}
        link=""
        icon={faHome}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Signout"
        active={pathname[1]}
        icon={faSignOut}
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
};

const Item = (params) => {
  return (
    <div
      onClick={() => {
        if (params.options?.length === 0) {
          window.location.href = params.url;
        }
        if (params.url == "/logout") {
          localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
          window.location.href = "/login";
        }
      }}
      onMouseEnter={() => {}}
      className={params.link == params.active ? "active" : "item"}
    >
      <FontAwesomeIcon icon={params.icon} />
      <p>{params.txt}</p>
    </div>
  );
};
