import {
  faAddressBook,
  faEnvelope,
  faLocation,
  faMoneyBill,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function CustomerBox(props) {
  const [color, setColor] = useState("orange");
  useEffect(() => {
    switch (props?.item?.PreferredPayment) {
      case "Bank Transfer":
        setColor("#2B3499");
        break;
      case "Cheque":
        setColor("#4F6F52");
        break;
      case "Mobile Money":
        setColor("green");
      case "Cash":
        setColor("#FFC436");
        break;
      default:
        break;
    }
  }, [props.item]);
  return (
    <div
      className={
        props.userID == props?.item?.UserID
          ? "customer-box active"
          : "customer-box"
      }
      onClick={() => {
        props.setUserID(props?.item?.UserID);
      }}
    >
      <div className="left">
        <h2
          style={{
            backgroundColor: color,
          }}
        >
          <span>{props?.item?.PreferredPayment}</span>
        </h2>
      </div>
      <div className="right">
        <h3 className="title">{props?.item?.Company}</h3>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faLocation}
          />
          {props?.item?.Address}
        </p>
        <p className="dark">
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            color="#0064B6"
            icon={faMoneyBill}
          />
          {props?.item?.Currency}
        </p>
      </div>
    </div>
  );
}
